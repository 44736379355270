<template>
  <v-app class="ma-0 pa-0">
    <audio ref="audioAlert" src="../src/assets/sounds/alert.mp3"></audio>
    <Menu v-if="!hideMenu" />
    <AppBar v-if="!hideMenu" />
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "./components/Menu/Menu.vue";
// import AppBar from "./components/AppBar/AppBar.vue";

export default {
  name: "Mis",

  components: {
    Menu,
    AppBar: () => import('./components/AppBar/AppBar.vue')
    // MyComponent: () => import('./MyComponent.vue')
  },
  created() {
    this.audio = new Audio();
  },
  data: () => ({
    count: 1,
  }),
  computed: {
    hideMenu() {
      return this.$route.meta.hideMenu || false;
    },
  },
  mounted() {},
  methods: {
    play(url) {
      this.$store.commit("patients/playAudio", url);
    },
    pause() {
      this.$store.commit("patients/pauseAudio");
    },
  },
  watch: {
    "$store.state.patients.notifications"() {
      var notifications = this.$store.state.patients.notifications;

      // Emits audible alerts by necessity
      for (var i = 1; i <= notifications; i++) {
        setTimeout(() => {
          try {
            this.$refs.audioAlert.play();
          } catch (error) {
            console.log(error);
          }
        }, i * 5000);
      }

      this.$store.dispatch("patients/updateNotifications", 0, { root: true })
    },
  },
};
</script>
