import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import store from "./store";
import router from "./router/router";
import vuetify from "./plugins/vuetify";
import ECharts from "vue-echarts";
import VueMask from "v-mask";
import VueSweetalert2 from "vue-sweetalert2";
import JsonExcel from "vue-json-excel";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from 'moment'

import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { use } from "echarts/core";

import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

Vue.prototype.moment = moment

Vue.config.productionTip = false;

Vue.component("v-chart", ECharts);
Vue.component("downloadExcel", JsonExcel);

Vue.use(VueMask);
Vue.use(VueSweetalert2);

store.dispatch("authentication/validateSession", null, { root: true });

if (store.getters["authentication/hasPermission"]("doctor", "attendance")) {
  setInterval(async () => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (!currentUser.id) return;

    if (store.getters["authentication/hasPermission"]("doctor", "attendance")) {
      await store.dispatch("patients/getPatients", null, { root: true });
    }
  }, 60000);
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
