/* eslint-disable */
import VueRouter from "vue-router";
import { axios } from "../../plugins/axios";
import jwtDecode from "jwt-decode";
import Vue from "vue";

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  // currentUser: JSON.parse(localStorage.currentUser || "{}"),
  currentUser: localStorage.currentUser
    ? JSON.parse(localStorage.currentUser)
    : { institution: {} },
  rememberMe: localStorage.rememberMe || false,
});

// getters
const getters = {
  jwtPayload(state) {
    if (!(state.currentUser && state.currentUser.token)) return {};

    return jwtDecode(state.currentUser.token);
  },
  isJwtExpired(_state, getters) {
    return Date.now() >= (getters.jwtPayload.exp || 0) * 1000;
  },
  hasPermission:
    (state) =>
    (...roles) => {
      if (!(state.currentUser && state.currentUser.role)) return false;
      if (!roles.length) return true;
      const userRole = state.currentUser.role.default_to;

      return roles.some((role) => role === userRole);
    },
};

// actions
const actions = {
  validateSession: async ({ dispatch, state, getters, commit }) => {
    if (!(state.currentUser && state.currentUser.token)) {
      return;
    }
    if (getters.isJwtExpired) {
      dispatch("logout");
    } else {
      try {
        await dispatch("login", {});
      } catch (error) {
        commit("setCurrentUser", { user: {} });
        // dispatch("alerts/deleteAllAlerts", [], { root: true })
      }
    }
  },
  login: async ({ commit, dispatch, getters }, user) => {
    try {
      const response = await axios.post("/login", { user: user });

      commit("setCurrentUser", { user: response.data });

      if (
        window.location.pathname.split("/").includes("attendance") &&
        getters.hasPermission("attendance")
      ) {
        Vue.swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          icon: "success",
          title: "MIS",
          text: "Login realizado com sucesso",
        });

        // Validates if the user is a doctor or help desk
        await dispatch("patients/getPatients", null, { root: true });
      } else if (
        window.location.pathname.split("/").includes("admin") &&
        getters.hasPermission("admin")
      ) {
        Vue.swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          icon: "success",
          title: "MIS",
          text: "Login realizado com sucesso",
        });
      } else if (
        window.location.pathname.split("/").includes("doctor") &&
        getters.hasPermission("doctor")
      ) {
        Vue.swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          icon: "success",
          title: "MIS",
          text: "Login realizado com sucesso",
        });

        // Validates if the user is a doctor or help desk
        await dispatch("patients/getPatients", null, { root: true });
      } else if (
        window.location.pathname.split("/").includes("manager") &&
        getters.hasPermission("manager")
      ) {
      } else
        throw new Error("Apenas contas autorizadas podem acessar esta página");
    } catch (error) {
      if (error.message.split(" ").includes("401")) {
        Vue.swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          icon: "error",
          title: "MIS",
          text: "CPF ou senha inválidos.",
        });
      }
    }
  },
  logout: async ({ commit, dispatch }) => {
    try {
      try {
        await axios.delete("/logout");

        commit("setCurrentUser", { user: {} });
        localStorage.removeItem("currentUser");
      } catch (error) {}

      await dispatch("patients/updatePatients", [], { root: true });
      await dispatch("alerts/updateAlerts", [], { root: true });
      await dispatch("patients/updateNotifications", 0, { root: true });
    } catch (error) {}
  },
};

// mutations
const mutations = {
  setCurrentUser(state, { user }) {
    state.currentUser = user || {};
    localStorage.currentUser = JSON.stringify(state.currentUser);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
