/* eslint-disable */
import { axios } from "../../plugins/axios";

const state = () => ({
  patients: JSON.parse(localStorage.patients || "[]"),
  notifications: JSON.parse(localStorage.notifications || 0),
});

// getters
const getters = {
  connectedPatients: (state) =>
    state.patients.filter((patient) => patient.status === 'Ligado'),
  disconnectedPatients: (state) =>
    state.patients.filter((patient) => patient.status == "Desligado"),
  findPatient: (state) => (id) => {
    return state.patients.find((patient) => patient.id == id);
  },
  findPatients: (state) => (ids) => {
    return state.patients.filter((patient) =>
      ids.find((el) => el.id == patient.id)
    );
  },
};

// actions
const actions = {
  getPatients: async ({ dispatch, commit, state, rootState, rootGetters }) => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // Patient monitoring service by healthcare professionals
    if (
      rootGetters["authentication/hasPermission"]("doctor") &&
      currentUser != {}
    ) {
      const response = await axios
        .get(`users/`, { params:{ doctor: true, }})
        .catch((err) => console.log("Get patients error: ", err));
      
      if (!response)       return;
      if (!response.data)  return;

      const alerts = response.data.map(async (patient) => {
        // Receive last sensors save
        const PatientLastSave = patient.receive_last_items;
        const currentDate = new Date();

        
        // 15 minutes ago
        const dateOfMinuteLast = new Date(
          currentDate.getTime() - 20 * 60000
        ).toLocaleTimeString();

        // Parameters for inserting alerts
        function params() {
          const types = {
            temperature_dropped: {
              id: PatientLastSave.alert.id,
              title: "Temperatura reduzida",
              color: "#ED7F30",
              value: PatientLastSave.temperature.real_temperature + "°C",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            temperature_rose: {
              id: PatientLastSave.alert.id,
              title: "Temperatura elevada",
              color: "#CF4444",
              value: PatientLastSave.temperature.real_temperature + "°C",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            beats_dropped: {
              id: PatientLastSave.alert.id,
              title: "Batimentos reduzidos",
              color: "#ED7F30",
              value: PatientLastSave.ecg.bpm + "BPM",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            beats_rose: {
              id: PatientLastSave.alert.id,
              title: "Batimentos elevados",
              color: "#CF4444",
              value: PatientLastSave.ecg.bpm + "BPM",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            dbp_dropped: {
              id: PatientLastSave.alert.id,
              title: "DBP reduzido",
              color: "#ED7F30",
              value: PatientLastSave.ecg.dbp + "DBP",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            dbp_rose: {
              id: PatientLastSave.alert.id,
              title: "DBP elevado",
              color: "#CF4444",
              value: PatientLastSave.ecg.dbp + "DBP",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            sbp_dropped: {
              id: PatientLastSave.alert.id,
              title: "SBP reduzido",
              color: "#ED7F30",
              value: PatientLastSave.ecg.sbp + "SBP",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            sbp_rose: {
              id: PatientLastSave.alert.id,
              title: "SBP elevado",
              color: "#CF4444",
              value: PatientLastSave.ecg.sbp + "SBP",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            blood_oxygen_dropped: {
              id: PatientLastSave.alert.id,
              title: "Oxigenação reduzida",
              color: "#CF4444",
              value: PatientLastSave.ecg.blood_oxygen + "SaO2",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            blood_oxygen_rose: {
              id: PatientLastSave.alert.id,
              title: "Oxigenação elevada",
              color: "#ED7F30",
              value: PatientLastSave.ecg.blood_oxygen + "SaO2",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            total_sleep_dropped: {
              id: PatientLastSave.alert.id,
              title: "Horas de sono reduzida",
              color: "#ED7F30",
              value:
                (PatientLastSave.sleep.deep_sleep_total +
                  PatientLastSave.sleep.light_sleep_total) /
                60,
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            total_sleep_rose: {
              id: PatientLastSave.alert.id,
              title: "Horas de sono elevada",
              color: "#CF4444",
              value:
                (PatientLastSave.sleep.deep_sleep_total +
                  PatientLastSave.sleep.light_sleep_total) /
                60,
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            falldown_exam: {
              id: PatientLastSave.alert.id,
              title: "Alerta de queda",
              color: "#CF4444",
              value: "Queda",
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            fence_out_exam: {
              id: PatientLastSave.alert.id,
              title: "Fora de Cerca",
              color: "#CF4444",
              value: 'Fora de Cerca',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            fence_in_exam: {
              id: PatientLastSave.alert.id,
              title: "Area não permitida",
              color: "#CF4444",
              value: 'Area limite',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            sos_exam: {
              id: PatientLastSave.alert.id,
              title: "SoS Acionado",
              color: "#CF4444",
              value: 'SOS',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            low_battery_exam: {
              id: PatientLastSave.alert.id,
              title: "Pouca Bateria",
              color: "#CF4444",
              value: 'Energia',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            wifi_in_exam: {
              id: PatientLastSave.alert.id,
              title: "Dentro de Cerca",
              color: "#CF4444",
              value: 'WiFi',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            wifi_out_exam: {
              id: PatientLastSave.alert.id,
              title: "Fora de Cerca",
              color: "#CF4444",
              value: 'WiFi',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            device_offline_exam: {
              id: PatientLastSave.alert.id,
              title: "Dispositivo Offline",
              color: "#CF4444",
              value: 'Offline',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
            sedentary_exam: {
              id: PatientLastSave.alert.id,
              title: "Tempo ocioso",
              color: "#CF4444",
              value: 'Sedentarismo',
              risk: PatientLastSave.alert ? PatientLastSave.alert.risk : "none",
              created_at: PatientLastSave.alert ? PatientLastSave.alert.created_at : "none",
            },
          };

          return (
            types[
              PatientLastSave.alert ? PatientLastSave.alert.status : "nothing"
            ] || {
              id: "none",
              title: "Nenhuma notificação",
              color: "#256DF4",
              risk: "none",
            }
          );
        }

        // Validates whether the patient is online or offline
        if (PatientLastSave.device_alert) {
          if (PatientLastSave.device_alert.created_at !== null) {
            if (
              PatientLastSave.device_alert.created_at.slice(11, 19) <=
                dateOfMinuteLast &&
              PatientLastSave.device_alert.status != "offline"
            ) {
              try {
                await axios.post("/device_alerts/", {
                  device_alert: {
                    user_id: patient.id,
                    status: "offline",
                  },
                });
              } catch (error) {
              }
            }
          }
        }

        if (PatientLastSave.alert == null) {
          PatientLastSave.alert = {
            id: "null",
          };
        }

        // Server-level processing possible
        if (PatientLastSave.device_alert == null) {
          PatientLastSave.device_alert = {
            status: "offline",
            created_at: null,
          };
        }

        // Server-level processing possible
        if (PatientLastSave.ecg == null) {
          PatientLastSave.ecg = {
            sbp: 0,
            dbp: 0,
            bpm: 0,
            blood_oxygen: 0,
          };
        }

        // Server-level processing possible
        if (PatientLastSave.temperature == null) {
          PatientLastSave.temperature = { real_temperature: 0 };
        }

        // Server-level processing possible
        if (PatientLastSave.sleep == null) {
          PatientLastSave.sleep = {
            deep_sleep_total: 0,
            light_sleep_total: 0,
          };
        }

        const uniquePatient = state.patients.some((el) => el.id === patient.id);

        // Validates if the patient already exists
        if (!uniquePatient) {
          await commit("setPatients", {
            patients: [
              ...state.patients,
              {
                id: patient.id,
                role_id: patient.role_id,
                name: patient.name,
                age: patient.age,
                profile_icon_url: patient.profile_icon_url,
                PA: `${PatientLastSave.ecg.dbp}x${PatientLastSave.ecg.sbp}`,
                FC: PatientLastSave.ecg.bpm,
                // FR: 4.0,
                temperature: PatientLastSave.temperature.real_temperature || 0,
                saturation: PatientLastSave.ecg.blood_oxygen,
                sleep: (
                  (PatientLastSave.sleep.deep_sleep_total +
                    PatientLastSave.sleep.light_sleep_total) /
                  60
                ).toFixed(1),
                status:
                  PatientLastSave.device_alert.status == "online"
                    ? "Ligado"
                    : "Desligado",
                statusColor:
                  PatientLastSave.device_alert.status == "online"
                    ? "green"
                    : "red",
                in_attendance: PatientLastSave.in_attendance,
                menu: "mdi-dots-vertical",
              },
            ],
          });
        }

        // If the patient exists, update the same
        if (uniquePatient) {
          commit("updatePatient", {
            patient: {
              id: patient.id,
              role_id: patient.role_id,
              name: patient.name,
              age: patient.age,
              profile_icon_url: patient.profile_icon_url,
              PA: `${PatientLastSave.ecg.dbp}x${PatientLastSave.ecg.sbp}`,
              FC: PatientLastSave.ecg.bpm,
              // FR: 4.0,
              temperature: PatientLastSave.temperature.real_temperature || 0,
              saturation: PatientLastSave.ecg.blood_oxygen,
              sleep: (
                (PatientLastSave.sleep.deep_sleep_total +
                  PatientLastSave.sleep.light_sleep_total) /
                60
              ).toFixed(1),
              status:
                PatientLastSave.device_alert.status == "online"
                  ? "Ligado"
                  : "Desligado",
              statusColor:
                PatientLastSave.device_alert.status == "online"
                  ? "green"
                  : "red",
              in_attendance: PatientLastSave.in_attendance,
              menu: "mdi-dots-vertical",
            },
          });
        }

        const uniqueAlerts = rootState.alerts.alerts.some(
          (el) => el.user_id === patient.id
        );

        // Validates if the alert already exists
        // Add setTimeout + sleep (seconds of audios)
        if (!uniqueAlerts) {
          if (params(PatientLastSave).risk !== "none") {
            commit("playAudio");
            commit("alerts/setOrder", null, { root: true });
          }
        }

        return {
          id: params(PatientLastSave).id,
          user_id: patient.id,
          role_id: patient.role_id,
          title: params(PatientLastSave).title,
          color: params(PatientLastSave).color,
          value: params(PatientLastSave).value,
          risk: params(PatientLastSave).risk,
          created_at: params(PatientLastSave).created_at,
          patient: {
            name: patient.name,
            age: patient.age,
            phone: patient.phone,
          },
        };
      });

      await dispatch(
        "alerts/updateAlerts",
        await Promise.all(alerts),
        { root: true }
      );
    }

    commit("alerts/setOrder", null, { root: true });
    dispatch("alerts/soundNotify", {}, { root: true });
  },
  deletePatient: async ({ commit }, patient) => {
    commit("deletePatient", { patient: patient });
  },
  updatePatients: async ({ commit }, patients) => {
    commit("setPatients", { patients: patients });
  },
  updateNotifications: async ({ commit }, notification) => {
    commit("setNotification", { notification: notification });
  },
};

// mutations
const mutations = {
  setPatients(state, { patients }) {
    state.patients = patients || [];
    localStorage.patients = JSON.stringify(state.patients);
  },
  updatePatient(state, { patient }) {
    const patients = state.patients;

    patients.filter((el) => {
      if (patient.id === el.id) {
        const index = patients.indexOf(el);
        patients[index] = patient;
      }
    });

    state.patients = patients;
    localStorage.patients = JSON.stringify(patients);
  },
  deletePatient(state, { patient }) {
    const patients = state.patients.filter(
      (el) => el.id !== patient.patient.id
    );

    state.patients = patients;
    localStorage.patients = JSON.stringify(patients);
  },
  setNotification(state, { notification }) {
    state.notifications = notification || 0;
    localStorage.notifications = JSON.stringify(state.notifications);
  },
  playAudio(state) {
    state.notifications = state.notifications + 1;
    localStorage.notifications = JSON.stringify(state.notifications);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
