/* eslint-disable */
// import Vue from 'vue'

import axios from "axios";
import _default from "vuex";

const state = () => ({
  alerts: JSON.parse(localStorage.alerts || "[]"),
  lastAudioNotification: Date.parse('2018-01-01')
});

// getters
const getters = {
  filterPatientAlerts: (state) => (created_at) => {
    return state.alerts.filter((alert) => alert.created_at == created_at);
  },
  findAlert: (state) => (id) => {
    return state.alerts.filter((alert) => alert.id == id)
  },
  filterByRisk: (state) => (risk) => {
    // se o tamanho do array for maior que 0, então o usuário já tem alertas
    if (state.alerts.length > 0 && state.alerts.status != [10,11]) {
    return state.alerts.filter((alert) => alert.risk == risk);
    }
  },
};

// actions
const actions = {
  updateAlerts: async ({ commit }, alerts) => {
    if(alerts.status != [10,11]){
      commit("setAlerts", { alerts: alerts });
    }
  },
  deleteAlert: async ({ commit }, alert) => {
    commit("deleteAlert", { alert: alert });
  },
  soundNotify: async (context) => {
    // console.log("soundNotify 0", context)
    if (!context.state.alerts) return
    if (!context.state.alerts.length) return

    // console.log("soundNotify 1")

    // Group alerts by user_id
    const grouped_alerts = context.state.alerts.reduce((acc, curr) => {
      acc[curr.user_id] = [...acc[curr.user_id] || [], curr]
      return acc
    }, {});
    // console.log("soundNotify 2", grouped_alerts)
    
    // Triggering sound notification for each user
    for (let user_id in grouped_alerts) {
      // console.log("soundNotify 3", user_id, context.state.lastAudioNotification)
      if (!user_id) continue
      
      const last_alert = grouped_alerts[user_id].find(a => Date.parse(a.created_at) > context.state.lastAudioNotification)
      
      // console.log("soundNotify 4", last_alert)
      if (!last_alert) continue

      context.commit("patients/playAudio", {}, { root: true });
      context.commit("setLastAudioNotification", { lastAudioNotification: Date.parse(last_alert.created_at) });
    }
  },
};

// mutations
const mutations = {
  setAlerts(state, { alerts }) {
    state.alerts = alerts || [];
    localStorage.alerts = JSON.stringify(state.alerts);
  },
  setOrder(state) {
    state.alerts = state.alerts.sort((before, after) => {
      if (Date.parse(before.id) < Date.parse(after.id)) return 1;
      if (Date.parse(before.id) > Date.parse(after.id)) return -1;
      return 0
    });
  },
  deleteAlert(state, { alert }) {
    const alerts = state.alerts.filter(
      (el) => el.id !== alert.alert.id
    );

    state.alerts = alerts;
    localStorage.alerts = JSON.stringify(alerts);
  },
  setLastAudioNotification(state, { lastAudioNotification }) {
    state.lastAudioNotification = lastAudioNotification;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
