<template>
  <v-card v-if="!$vuetify.breakpoint.mobile">
    <v-navigation-drawer width="180" color="#F8F8F8" permanent floating app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h4 logo"> 
            <v-img
            width="100%"
            src="../../assets/logoMis.png"
            lazy-src="../../assets/logoMis.png"
            ></v-img>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav>
        <div class="" v-for="(item, index) in items" :key="index">
          <!-- v-if="hasPermission(...item.permissions)" -->
          <v-list-item
            v-if="hasPermission(...item.permissions)"
            :to="
              item.path
                ? {
                    path: item.path,
                  }
                : ''
            "
            @click="item.method ? onClick(item.method) : ''"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-h9">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-list dense nav>
            <v-list-item link class="pa-2">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Ajustes</v-list-item-title>
              </v-list-item-content>
            </v-list-item></v-list
          >
        </div>
      </template> -->
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    selectedItem: 1,
    openBeforeCreatePatient: false,
    openCreatePatient: false,
    openCreatedPatient: false,
    lastPatientCreatedId: null,
    items: [
      {
        name: "Anamnese",
        icon: "mdi-file-multiple",
        path: "/admin/anamnesis",
        permissions: ["admin"],
      },
      {
        name: "Profissionais",
        icon: "mdi-account-multiple",
        path: "/admin/professionals",
        permissions: ["admin"],
      },
      {
        name: "Dashboard",
        icon: "mdi-home-variant",
        path: "/dashboard",
        permissions: ["doctor"],
      },
      {
        name: "Cadastrar",
        icon: "mdi-account-plus",
        path: "/create_patient",
        permissions: ["doctor"],
      },
      {
        name: "Pacientes",
        icon: "mdi-account-multiple",
        path: "/list_patient",
        permissions: ["doctor"],
      },
      {
        name: "Atendimentos",
        icon: "mdi-note-multiple",
        path: "/attendance/attendancies",
        permissions: ["doctor"],
      },
      {
        name: "Solicitações",
        icon: "mdi-plus",
        path: "/services",
        permissions: ["doctor"],
      },
      {
        name: "Solicitações",
        icon: "mdi-chat-alert",
        path: "/admin/services",
        permissions: ["admin"],
      },
      {
        name: "Painel",
        icon: "mdi-view-dashboard",
        path: "/manager/management-panel",
        permissions: ["manager"],
      },
      {
        name: "Listagem",
        icon: "mdi-account-multiple",
        path: "/manager/profissionais",
        permissions: ["manager"],
      },
    ],
  }),
  methods: {
    onClick(method) {
      switch (method) {
        case "openBeforeCreatePatient":
          this.openBeforeCreatePatient = true;
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "authentication/hasPermission",
    }),
  },
};
</script>

<style>
.logo {
  color: #1c3b7b;
}
</style>
